import htmx from 'htmx.org';

const ext = 'treatment-selector';

(() => {
  htmx.defineExtension(ext, {
    onEvent: function (name, event) {
      if (name !== 'htmx:afterProcessNode') {
        return;
      }

      const el = (event.target || event.detail.elt) as HTMLElement;
      el.addEventListener('sl-select', (e) => {
        const item = (<CustomEvent>e).detail.item;
        const treatment = item.getAttribute('value');

        htmx
          .ajax('POST', '/set-treatment', {
            swap: 'none',
            values: { selections: { treatment: treatment } }
          })
          .then(() => {
            location.reload();
          });
      });
    }
  });
})();
