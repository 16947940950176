import 'photoswipe/style.css';
import MagicGrid from 'magic-grid';
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import htmx from 'htmx.org';

const initializeGalleries = () => {
  const magicGrid = new MagicGrid({
    container: '.magic-gallery',
    items: 12,
    gutter: 2,
    maxColumns: 3,
    useMin: true,
    animate: false
  });

  magicGrid.listen();

  const lightbox = new PhotoSwipeLightbox({
    gallery: '.magic-gallery',
    children: '.magic-item',
    counter: false,
    zoom: false,
    loop: false,
    pswpModule: () => import('photoswipe')
  });

  refreshGallery(magicGrid, lightbox);

  htmx.on('htmx:afterSettle', () => {
    refreshGallery(magicGrid, lightbox);
  });
};

const refreshGallery = (magicGrid: MagicGrid, lightbox: PhotoSwipeLightbox) => {
  if (Object.keys(lightbox._listeners).length === 0) {
    lightbox.on('contentActivate', ({ content }) => {
      const galleryItemCount = magicGallery.querySelectorAll('.magic-item').length;
      if (content.index === galleryItemCount - 2) {
        const lastItem = magicGallery.lastElementChild as HTMLDivElement;
        const page = lastItem.dataset['nextPage'] as string;
        const pagingUrlBase = document.querySelector('[data-paging-url-base]')?.getAttribute('data-paging-url-base');
        lastItem.setAttribute('hx-disable', 'true');
        htmx
          .ajax('GET', `${pagingUrlBase}/page:${page}`, {
            target: '.magic-gallery',
            swap: 'beforeend'
          })
          .then(() => {
            const nextPage = parseInt(page) + 1;
            const newElements = document.querySelectorAll(`[data-next-page="${nextPage}"]`);
            const { pswp } = lightbox;
            newElements.forEach((element) => {
              // @ts-expect-error Property 'items' does not exist on type 'DataSource'
              pswp.options.dataSource.items.push(element);
            });
          });
      }
    });

    lightbox.on('close', () => {
      const { pswp } = lightbox;
      const currentEl = pswp?.currSlide?.data.element;
      if (currentEl) {
        currentEl.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    });
  }
  magicGrid.positionItems();
  lightbox.init();
};

const magicGallery = document.querySelector('.magic-gallery') as HTMLDivElement;
const minGalleryWidth = 768;
if (magicGallery && window.screen.width >= minGalleryWidth) {
  initializeGalleries();
}
